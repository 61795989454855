<template>
  <div class="flex space-y-2 flex-col justify-center items-center p-4 w-full lg:w-4/12">
    <img :src="src" alt="">
  </div>
</template>
<script>
export default {
  name: 'SecondaryCard',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>