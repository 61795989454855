<template>
  <div
      class="w-10/12 h-fit mx-auto flex flex-col justify-center items-center overflow-hidden-x">

    <section class="bg-white">
      <div class="container px-4 mx-auto">
        <div class="max-w-4xl mx-auto text-center">
          <h2 class="mb-4 text-3xl md:text-4xl font-heading font-bold">Cansado de soluções genéricas?</h2>
          <p class="mb-6 text-lg md:text-xl font-heading font-medium text-coolGray-500">
            Nossa abordagem diversificada e transparente garante que você receba soluções personalizadas e inovadoras para seus desafios.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'ProjectsComponent',
}
</script>
