<template>
  <div id="services"
       class="w-10/12 h-fit mx-auto flex flex-col justify-center lg:mb-12 items-center overflow-hidden-x">
    <section id="services"  class="py-24 bg-white">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 mb-16">
          <div class="w-full md:w-1/2 mb-16 md:mb-0">
            <span class="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm">O que nós fazemos</span>
            <h2 class="mb-6 text-4xl md:text-5xl leading-tight font-bold tracking-tighter">Fornecemos a solução perfeita para o desenvolvimento das suas ideias</h2>
            <p class="text-lg md:text-xl text-coolGray-500 font-medium">
              Transformamos suas visões em produtos e serviços inovadores que impulsionam o crescimento do seu negócio.
            </p>
          </div>
          <div class="w-full md:w-1/2">
            <div class="relative mx-auto md:mr-0 max-w-max overflow-hidden rounded-7xl">
              <a class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-flex items-center justify-center w-16 h-16 bg-green-500 hover:bg-green-600 rounded-full" href="#">
                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 11.13L5.50001 3.05C5.34799 2.96223 5.17554 2.91603 5.00001 2.91603C4.82447 2.91603 4.65203 2.96223 4.50001 3.05C4.3474 3.1381 4.22079 3.26497 4.13299 3.41775C4.04518 3.57052 3.99931 3.74379 4.00001 3.92V20.08C3.99931 20.2562 4.04518 20.4295 4.13299 20.5823C4.22079 20.735 4.3474 20.8619 4.50001 20.95C4.65203 21.0378 4.82447 21.084 5.00001 21.084C5.17554 21.084 5.34799 21.0378 5.50001 20.95L19.5 12.87C19.6539 12.7828 19.7819 12.6563 19.871 12.5035C19.96 12.3506 20.007 12.1769 20.007 12C20.007 11.8231 19.96 11.6494 19.871 11.4965C19.7819 11.3437 19.6539 11.2172 19.5 11.13ZM6.00001 18.35V5.65L17 12L6.00001 18.35Z" fill="white"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap -mx-4 text-center md:text-left">
          <div class="w-full md:w-1/3 px-4 mb-8">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">1</div>
            <h3 class="mb-2 text-xl font-bold">Softwares Personalizados</h3>
            <p class="font-medium text-coolGray-500">Tem uma ideia inovadora para um produto web, mas não sabe por onde começar? Conte conosco para ajudá-lo a
              transformar sua visão em realidade.</p>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-8">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">2</div>
            <h3 class="mb-2 text-xl font-bold">Criação de E-commerce</h3>
            <p class="font-medium text-coolGray-500">
              Crie sua loja virtual com a gente. Desenvolvemos plataformas personalizadas e
              integradas para atender às necessidades do seu negócio.
            </p>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-8">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">3</div>
            <h3 class="mb-2 text-xl font-bold">Criação de apps</h3>
            <p class="font-medium text-coolGray-500">
              Precisa de um aplicativo multiplataforma e não sabe por onde começar? Não se preocupe podemos te ajudar.
            </p>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">4</div>
            <h3 class="mb-2 text-xl font-bold">Criação de site</h3>
            <p class="font-medium text-coolGray-500">
              Seu site, do jeito que você sempre quis, sem complicações. Desenvolvemos sites modernos e responsivos, adaptando-os às suas necessidades.
            </p>
          </div>
          <div class="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">5</div>
            <h3 class="mb-2 text-xl font-bold">Modernizamos aplicações</h3>
            <p class="font-medium text-coolGray-500">
              Atualizamos e modernizamos sistemas legados, migrando para arquiteturas mais eficientes e tecnologias atuais,
              garantindo a escalabilidade.
            </p>
          </div>
          <div class="w-full md:w-1/3 px-4">
            <div class="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl text-white bg-green-500 font-semibold rounded-full">6</div>
            <h3 class="mb-2 text-xl font-bold">Automatize processos</h3>
            <p class="font-medium text-coolGray-500">
              Desenvolvemos: CRM, automação de marketing, gerenciamento de vendas e muito mais. Otimize seus processos e aumente sua receita.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ServicesComponent',
  components: {

  },

  data() {
    return {
      SoftwareCharacteristics: {
        1: "Análise detalhada das necessidades e requisitos do cliente.",
        2: "Desenvolvimento de soluções exclusivas e personalizadas.",
        3: "Interação constante entre a equipe de desenvolvimento e o cliente para garantir alinhamento.",
        4: "Flexibilidade para ajustar e adaptar o software conforme as necessidades evoluem.",
        5: "Foco na escalabilidade e na capacidade de expansão para atender ao crescimento futuro do negócio."
      },
      ProductCharacteristics: {
        1: "Identificação de oportunidades de mercado e análise de demandas.",
        2: "Desenvolvimento de produtos com funcionalidades abrangentes e atraentes.",
        3: "Consideração de fatores de usabilidade e experiência do usuário.",
        4: "Ciclos de desenvolvimento e lançamento de produtos planejados.",
        5: "Estratégias de marketing e distribuição para alcançar o público-alvo."
      },

      ConsultancyCharacteristics: {
        1: "Análise das necessidades e desafios específicos do cliente.",
        2: "Recomendações personalizadas para melhorar a infraestrutura e os processos de TI.",
        3: "Avaliação de riscos e segurança da informação.",
        4: "Identificação de oportunidades para adotar novas tecnologias.",
        5: "Suporte na tomada de decisões estratégicas relacionadas à tecnologia."
      },
      SEOCharacteristics: {
        1: "Análise de palavras-chave e pesquisa de mercado.",
        2: "Otimização do conteúdo para palavras-chave relevantes.",
        3: "Melhoria na arquitetura do site para facilitar a indexação pelos mecanismos de busca.",
        4: "Estratégias de link building (construção de links) para aumentar a autoridade do site.",
        5: "Monitoramento e análise de métricas de desempenho para ajustes contínuo."
      },
    }
  },

}
</script>
