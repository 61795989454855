<template>
  <header>
    <nav-bar/>
  </header>
  <router-view/>
</template>
<script>
import NavBar from "@/components/partials/NavBar.vue";

export default {
  name: 'App',
  components: {NavBar},
}
</script>
