<template>
  <main class="relative">
    <Principal/>
    <Services/>
    <AboutMe/>
<!--    <Reviews />-->
    <projects-component/>
    <float-button-component/>
    <footer>
      <ContactComponent/>
      <Footer/>
    </footer>
  </main>
</template>

<script>
import AboutMe from '@/components/partials/AboutMe.vue';
// import Reviews from '@/components/partials/Reviews.vue';
import Services from '@/components/partials/ServicesComponent.vue';
import {onActivated} from 'vue';
import ProjectsComponent from "@/components/partials/ProjectsComponent.vue";
import Principal from "@/components/partials/Principal.vue";
import FloatButtonComponent from "@/components/partials/FloatButtonComponent.vue";
import Footer from "@/components/partials/Footer.vue";
import ContactComponent from "@/components/partials/ContactComponent.vue";

export default {
  name: 'HomeView',
  components: {
    ContactComponent, Footer,
    Principal,
    ProjectsComponent,
    AboutMe,
    Services,
    FloatButtonComponent
    // Reviews,
  },
  methods() {
    onActivated(() => {
      console.log('activated');
    });
  }
}
</script>
