<template>
  <div class="w-full mt-12">
    <div class="w-full lg:w-4/12">
      <h2 class="text-green uppercase text-xl">{{title}}</h2>
      <div class="w-14 bg-green h-2 rounded ml-"></div>
      <p class="text-lg mt-4">{{text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TitleCard',
  props : {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>