<template>

  <button class="text-orange hover:scale-105 p-2 lg:w-48">
    <a :href="link" :title="title">
     <span class="text-xl lg:text-2xl uppercase font-bold">{{ title }}</span>
    </a>
  </button>

</template>
<script>
export default {
  name: 'SecondaryButton',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>